import React from 'react';
import { Result, Button } from 'antd';
import { useNavigate } from 'react-router-dom';

function NotFound() {
  const navigate = useNavigate();
  return (
    <div style={{ height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <Result
        status="404"
        title="User Not Found"
        subTitle="Sorry, we couldn't find the user you're looking for."
        extra={[
          <Button
            type="primary"
            key="contact"
            onClick={() => window.location.href = 'mailto:survey@greatmanagers.org'}
          >
            Contact Us
          </Button>,
          <Button key="retry" onClick={() => navigate(-1)}>
            Retry
          </Button>,
        ]}
      />
    </div>
  );
}

export default NotFound;
